import OficioCorporativo from "../../models/OficioCorporativo";

const url = process.env.REACT_APP_BACKEND_URL;

export async function oficioCorporativo_get(
    params
){
    let query = url + '/api/oficiocorporativo';
    if (Object.keys(params).length >= 0)
        query += '?' + new URLSearchParams(params);

    const response = await fetch(query, {
        method: 'GET',
    }).then(async response => {
        if (response.status === 204){
            return {
                ok: false,
                data: 'Oficio no registrado en base de datos.'
            }
        } else if(response.ok){
            return response.json().then(
                payload => {
                    const result = [];
                    payload.data.forEach(entity => {
                        result.push(new OficioCorporativo(entity))
                    });
                    return {
                        ok: true,
                        data: result,
                    };
                }
            )
        }else{
            return {
                ok: false,
                data: response.text()
            };
        }
    }).catch(reason => {
        console.log('Error al invocar servicio /api/oficiocorporativo ' + reason);
    })

    return response;
}

    export async function oficioCorporativoFolio_post(fecha, oficio_ids, transportista_id, region_id){
        const endpoint = url + '/api/oficiocorporativo/folio';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fecha: fecha,
                oficio_ids: oficio_ids,
                transportista_id: transportista_id,
                region_id: region_id,
            }),
        }).then(async response =>  {
            if (response.ok){
                return response.json().then(payload => {
                    let oficio = null;
                    if (payload.oficio !== null){
                        oficio = new OficioCorporativo(payload.oficio);
                    }

                    return {
                        ok: oficio !== null,
                        oficio: oficio,
                        errors: payload.errors,
                    };
                });
            }else{
                return response.text().then(text => {
                    return {
                        ok: false,
                        data: text
                    }
                })
            }
        });

        return response;
    }