import { useEffect, useState } from "react";
import "./Tabla.css";
import { dineroFormatter } from "../util/StringFormat";

export default function Tabla(props){
    const {
        heads,
        entities,
        entityName,
        entityIdName,
        selected = null,
        setAll = () => {},
        setNone = () => {},
        toggleSelected = () => {},
        buscando,
        groupBy= null,
        ...rest
    } = props;

    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        if (selected !== null){
            if (entities.size > 0 && selected.size === entities.size)
                setAllSelected(true);
            else
                setAllSelected(false);
        }
    }, [entities, selected]);


    const handleAllChange = () => {
        if (!allSelected)
            setAll();
        else
            setNone();
    }

    const handleChange = (entityId) => {
        toggleSelected(entityId);
    }

    const renderTableHead = () => {
        const renderSelectAll = () => {
            if (selected !== null){
                return(
                    <th className="table-corner">
                        <input
                            id={entityName + "-all"}
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleAllChange}
                        />
                    </th>
                )
            }
        }

        const renderHeader = () => {
            const ths = [];

            if (groupBy !== null){
                ths.push(
                    <th key={'th-' + entityName + '-grouper'}>
                        {groupBy}
                    </th>
                )
            }

            heads.forEach(head => {
                ths.push(
                    <th key={'th-' + entityName + '-' + head.prop}>{head.title}</th>
                );
            });

            return ths;
        }

        return (
            <tr>
                {renderSelectAll()}
                {renderHeader()}
            </tr>
        )
    }

    const renderTableBody = () => {
        if (buscando){
            return(
                <tr >
                    <td>&nbsp;</td>
                    <td className="spinner-border text-primary text-center" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </td>
                </tr>
            )
        }

        const rows = [];

        const renderSelect =(entity) => {
            if (selected !== null){
                return(
                    <td>
                        <input
                            id={entityName + "-check-" + entity[entityIdName]}
                            type="checkbox"
                            checked= {selected.has(entity[entityIdName])}
                            onChange={(e) => handleChange(entity[entityIdName])}
                            ></input>
                    </td>
                );
            }
        }

        const renderRow = (entity) => {
            const cols = [];

            heads.forEach(head => {
                const col = head.dineroFormat ? dineroFormatter.format(entity[head.prop]) : entity[head.prop];

                cols.push(
                    <td key={'td-' + entityName + '-' + entity[entityIdName] + '-' + head.prop}>{col}</td>
                )
            });

            return cols;
        }

        if (groupBy !== null){
            const renderGrouper = (isFirst, groupKey, size) => {
                if (isFirst){
                    return (
                        <td
                            key={'td-' + entityName + '-' + groupKey + '-grouper'}
                            className="row-grouper"
                            rowSpan={size}
                        >
                            {groupKey}
                        </td>
                    );
                }
            }

            for (let [groupKey, group] of entities){
                let isFirst = true;
                group.forEach(entity => {
                    rows.push(
                        <tr key={entityName + "-" + entity[entityIdName]}>
                            {renderGrouper(isFirst, groupKey, group.size)}
                            {renderSelect(entity)}
                            {renderRow(entity)}
                        </tr>
                    );

                    isFirst = false;
                });
            }
        }else{
            entities.forEach(entity => {
                rows.push(
                    <tr key={entityName + "-" + entity[entityIdName]}>
                        {renderSelect(entity)}
                        {renderRow(entity)}
                    </tr>
                )
            });
        }

        return rows;
    }

    return (
        <div className="table-wrapper">
            <table className="table table-striped table-sm">
                <thead>
                    {renderTableHead()}
                </thead>
                <tbody>
                    {renderTableBody()}
               </tbody>
            </table>
        </div>
    );
}