import { useEffect, useState } from "react";
import Filtro from "../components/Filtro";
import GestionBase from "../components/GestionBase";
import { toDateInputValue } from "../util/Fecha";
import Tabla from "../components/Tabla";
import { oficioRegionalPeriodo_get, oficioRegional_get } from "../services/Api/OficioRegional";

import "./Corporativo.css";
import { ordenServicio_get } from "../services/Api/OrdenServicio";
import { ModalElement, showModal } from "../components/Modal";
import Total from "../components/Total";
import { oficioCorporativoFolio_post } from "../services/Api/OficioCorporativo";
import Situacion from "../models/Situacion";

Date.prototype.toDateInputValue = toDateInputValue;

export default function Corporativo(){
    const errorModalId = "modal-corporativo-error";

    const title = 'Gestión de órdenes de servicio';
    const subtitle = 'Generar folio corporativo';
    const image= "/img/icono_folio_corporativo.png"
    const totalesInicial = {
        nOrdenes: 0,
        importe: 0,
        subtotal: 0,
        iva: 0,
        total: 0,
    }
    const oficioHeads = [
        {
            title: 'Oficio',
            prop: 'id',
            dineroFormat: false,
        }
    ]
    const ordenHeads = [
        // {
        //     title: 'Oficio seleccionado',
        //     prop: 'oficio_regional_id',
        //     dineroFormat: false,
        // },
        {
            title: 'Num. orden',
            prop: 'numorden',
            dineroFormat: false,
        },
        {
            title: 'Fecha venta',
            prop: 'fecha_venta',
            dineroFormat: false,
        },
        {
            title: 'Fecha de viaje',
            prop: 'fecha_viaje',
            dineroFormat: false,
        },
        {
            title: 'Origen',
            prop: 'terminal_origen',
            dineroFormat: false,
        },
        {
            title: 'Destino',
            prop: 'terminal_destino',
            dineroFormat: false,
        },
        {
            title: 'Marca',
            prop: 'marca',
            dineroFormat: false,
        },
        {
            title: 'Región',
            prop: 'region',
            dineroFormat: false,
        },
        {
            title: 'Situación',
            prop: 'situacion',
            dineroFormat: false,
        },
        {
            title: 'Precio',
            prop: 'precio',
            dineroFormat: true,
        },
        {
            title: 'Boletos',
            prop: 'boletos',
            dineroFormat: false,
        },
        {
            title: 'Subtotal',
            prop: 'subtotal',
            dineroFormat: true,
        },
        {
            title: 'IVA',
            prop: 'iva',
            dineroFormat: true,
        },
        {
            title: 'Total',
            prop: 'total',
            dineroFormat: true,
        },
        {
            title: 'Dependencia',
            prop: 'dependencia',
            dineroFormat: false,
        },
    ]

    const pdfDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficiocorporativo/pdf/';
    const excelDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficiocorporativo/excel/';
    const zipDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficiocorporativo/zip/';

    const ordenesGroupBy = 'Oficio seleccionado';
    const [errorBody, setErrorBody] = useState('');
    const [fecha, setFecha] = useState(() => {
        const _fecha = new Date();
        return _fecha.toDateInputValue();
    });
    const [oficios, setOficios] = useState(new Map());
    const [oficiosSelected, setOficiosSelected] = useState(new Set());
    const [ordenes, setOrdenes] = useState(new Map());
    const [ordenesSelected, setOrdenesSelected] = useState(new Map());
    const [buscando, setBuscando] = useState(false);
    const [buscandoOrdenes, setBuscandoOrdenes] = useState(false);
    const [totales, setTotales] = useState(totalesInicial);
    const [generarFolioDisabled, setGenerarFolioDisalbled] = useState(true);
    const [generandoFolio, setGenerandoFolio] = useState(false);
    const [numeroOficio, setNumeroOficio] = useState('');
    const [periodos, setPeriodos] = useState({});
    const [añoSelected, setAñoSelected] = useState('');
    const [mesSelected, setMesSelected] = useState('');

    // const periodos = {
    //     '2024': ['Enero','Febrero','Marzo','Abril', 'Mayo'],
    //     '2023': ['Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    // }

    useEffect(() => {
        oficioRegionalPeriodo_get().then(result => {
            if (result.ok){
                setPeriodos(result.data);
                const primerAño = Object.keys(result.data)[0];
                setAñoSelected(primerAño);
                setMesSelected(result.data[primerAño][0]);
            }else{
                setErrorBody(result.data);
                showModal(errorModalId);
            }
        });
    }, []);

    useEffect(() => {
        // Actualizar totales
        let nOrdenes= oficiosSelected.size;
        let importe = 0;
        let subtotal = 0;
        let iva = 0;
        let total = 0;
        oficiosSelected.forEach(id => {
            const oficio = oficios.get(id);
            importe += oficio.precio;
            subtotal += oficio.subtotal;
            iva += oficio.iva;
            total += oficio.total;
        });
        setTotales({
            nOrdenes: nOrdenes,
            importe: importe,
            subtotal: subtotal,
            iva: iva,
            total: total,
        })

        // Actualizar botón generarFolio
        if (oficiosSelected.size <= 0){
            setGenerarFolioDisalbled(true);
        }else{
            let puedeGenerar = true;
            for (let id of oficiosSelected){
                const oficio = oficios.get(id);
                if (oficio.oficio_corporativo_id !== null
                    || oficio.situacion_id !== Situacion.Enum.ENVIADA.id
                ){
                    puedeGenerar = false;
                    break;
                }
            }
            setGenerarFolioDisalbled(!puedeGenerar);
        }
    }, [oficios, oficiosSelected])

    const limpiar = () => {
        setTotales(totalesInicial);
        setOrdenesSelected(new Map());
        setOrdenes(new Map());
        setOficiosSelected(new Set());
        setOficios(new Map());
        setNumeroOficio('');
    }

    const selectOficio = (id) => {
        // Agregar órdenes de serivio
        if (!ordenes.has(id)){
            setBuscandoOrdenes(true);
            ordenServicio_get({
                oficio_regional_id: id,
                page_size: 0,
            }).then(result => {
                if (result.ok){
                    const ordenesOficio = new Map();
                    result.data.forEach(orden => {
                        ordenesOficio.set(orden.numorden, orden);
                    });

                    setOrdenes(oldOrdenes => {
                        const newOrdenes = new Map(oldOrdenes);
                        newOrdenes.set(id, ordenesOficio)

                        return newOrdenes;
                    });

                    setOrdenesSelected(oldSelected => {
                        const newSelected = new Map(oldSelected);
                        newSelected.set(id, ordenesOficio);

                        return newSelected;
                    });
                }
                setBuscandoOrdenes(false);
            });
        }else{
            const ordenesOficio = ordenes.get(id);

            setOrdenesSelected(oldSelected => {
                const newSelected = new Map(oldSelected);
                newSelected.set(id, ordenesOficio);

                return newSelected;
            });
        }
    }

    const unselectOficio = (id) => {
        setOrdenesSelected(oldOrdenes => {
            const newOrdenes = new Map(oldOrdenes);
            newOrdenes.delete(id);

            return newOrdenes;
        });
    }

    const setAllOficiosSelected = () => {
        const newSelected = new Set();
        oficios.forEach(oficio => {
            newSelected.add(oficio.id);
            selectOficio(oficio.id);
        });
        setOficiosSelected(newSelected);
    }

    const setNoneOficiosSelected = () => {
        setOficiosSelected(oldSelected => {
            oldSelected.forEach(id => {
                unselectOficio(id);
            });

            return new Set();
        });
    }

    const toggleOficioSelected = (id) =>{
        setOficiosSelected(selected => {
            if (selected.has(id)){
                selected.delete(id);
                unselectOficio(id);
            }
            else{
                selected.add(id);
                selectOficio(id);
            }

            const newSelected = new Set(selected);
            return newSelected;
        })
    }

    const buscar = (numeroOficio, regiones, dependenciaId, transportistaId, situacionId, año, mes) => {
        setBuscando(true);
        setNoneOficiosSelected();

        let region_ids = '';
        regiones.forEach(region_id => {
            region_ids += region_id.toString() + ',';
        });

        let busquda = {}
        if (numeroOficio !== ''){
            busquda = {
                id: numeroOficio
            }
        }else{
            busquda = {
                region_ids: region_ids,
                dependencia_id: dependenciaId,
                transportista_id: transportistaId,
                situacion_id: situacionId,
                anio: año,
                mes: mes,
                page_size: 0,
            }
        }

        oficioRegional_get(busquda).then(result => {
            if (result.ok){
                const oficios = new Map();
                result.data.forEach(oficio => {
                    oficios.set(oficio.id, oficio);
                })

                setOficios(oficios);
            }
            else{
                setErrorBody(result.data);
                showModal(errorModalId);
        }
            setBuscando(false);
        });
    }

    const generarFolio = (dependenciaId, transportistaId, regionId) => {
        setGenerandoFolio(true);
        oficioCorporativoFolio_post(fecha, Array.from(oficiosSelected), transportistaId, regionId).then(
            result => {
                if (result.ok){
                    setNumeroOficio(result.oficio.id);

                    // Actualizar órdenes de servicio
                    const newOrdenes = new Map(ordenes);
                    const newOrdenesSeleced = new Map(ordenesSelected);
                    ordenesSelected.forEach((_ordenes, id) => {
                        _ordenes.forEach((orden) => {
                            orden.situacion_id = Situacion.Enum.POR_COBRAR.id;
                        })

                        newOrdenes.set(id, _ordenes);
                        newOrdenesSeleced.set(id, _ordenes);
                    });
                    setOrdenes(newOrdenes);
                    setOrdenesSelected(newOrdenesSeleced);

                    // Actualizar oficios regionales
                    const newOficios = new Map(oficios);
                    oficiosSelected.forEach(id => {
                        const oficio = oficios.get(id);
                        oficio.oficio_corporativo_id = result.oficio.id;
                        oficio.situacion_id = Situacion.Enum.POR_COBRAR.id;
                        newOficios.set(result.oficio.id, oficio);
                    });
                    setOficios(newOficios);

                    // Descargar archivos
                    window.open(zipDownloadUrl + result.oficio.id, '_blank');
                }else{
                    setErrorBody(result.data);
                    showModal(errorModalId);
                }
                setGenerandoFolio(false);
            }
        )
    }

    const renderTable = () => {
        return (
            <div className="h-100">
                <div className="row g-0 h-100">
                    <div className="col col-2 pe-1 h-100 overflow-y-auto">
                        <Tabla
                            heads= {oficioHeads}
                            entities= {oficios}
                            entityName= "oficio"
                            entityIdName= "id"
                            selected= {oficiosSelected}
                            setAll= {setAllOficiosSelected}
                            setNone= {setNoneOficiosSelected}
                            toggleSelected= {toggleOficioSelected}
                            buscando= {buscando}
                        />
                    </div>
                    <div className="col col-10 ordenes-table h-100 overflow-auto">
                        <Tabla
                            heads= {ordenHeads}
                            entities= {ordenesSelected}
                            entityName= "orden"
                            entityIdName= "numorden"
                            groupBy= {ordenesGroupBy}
                            buscando= {buscandoOrdenes}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <GestionBase
            title= {title}
            subtitle= {subtitle}
            image= {image}
            filtro= {<Filtro
                fecha = {fecha}
                setFecha = {setFecha}
                numeroOficio = {numeroOficio}
                setNumeroOficio = {setNumeroOficio}
                buscar = {buscar}
                periodos = {periodos}
                añoSelected = {añoSelected}
                setAñoSelected = {setAñoSelected}
                mesSelected = {mesSelected}
                setMesSelected = {setMesSelected}
                generarFolio = {generarFolio}
                generarFolioDisabled= {generarFolioDisabled}
                generandoFolio= {generandoFolio}
                limpiar= {limpiar}
                situacionInitialIndex='1'
                pdfDownloadUrl = {pdfDownloadUrl}
                excelDownloadUrl = {excelDownloadUrl}
                />}
            table = {renderTable()}
            totales = {<Total totales={totales}/>}
        >
            <ModalElement modalId= {errorModalId} body={errorBody} showCancelar={false}/>
        </GestionBase>
    );
}