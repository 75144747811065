import { Link } from "react-router-dom";

export default function NavMenuLink(props){
    const {path, name, image, location, isMenuOpen} = props;

    // const className = () => {
    //     return isActive ? "active" : "";
    // }

    const navLinkStyle = (isMenuOpen) => {
        if (isMenuOpen){
            return {
                'padding': '0 0.5rem',
                'maxWidth': '16rem',
            }
        }
        return {
            'padding': '0px',
            'maxWidth': '0px',
        }
    }

    return (
    <div className="nav-link">
        <Link to={path}>
            <div className={"row g-0 align-items-center " + (path === location ? "active" : "")}>
                <div
                    className="col-auto p-0"
                    data-toggle="tooltip"
                    data-placemente="right"
                    title={name}
                >
                    <img src={image} height="38px;"/>
                </div>
                <div className="col d-flex justify-content-center nav-link-sub p-0"
                    style={navLinkStyle(isMenuOpen)}
                >
                    <span className="ps-4 pe-4">
                        {name}
                    </span>
                </div>
            </div>
        </Link>
    </div>
    );
}

