import { useEffect, useState } from "react";
import Filtro from "../components/Filtro";
import GestionBase from "../components/GestionBase";
import Tabla from "../components/Tabla";
import Total from "../components/Total";
import { ordenServicio_get } from "../services/Api/OrdenServicio";
import Situacion from "../models/Situacion";
import { toDateInputValue } from "../util/Fecha";
import { oficioRegionalFolio_post } from "../services/Api/OficioRegional";
import { ModalElement, showModal } from "../components/Modal";

Date.prototype.toDateInputValue = toDateInputValue;

export default function Region(){
    const errorModalId = "modal-region-error";

    const title = 'Gestión de órdenes de servicio';
    const subtitle = 'Oficio de servicio';
    const image = '/img/icono_oficio_servicio.png';
    const totalesInicial = {
        nOrdenes: 0,
        importe: 0,
        subtotal: 0,
        iva: 0,
        total: 0,
    }
    const tableHeads = [
        {
            title: 'Num. orden',
            prop: 'numorden',
            dineroFormat: false,
        },
        {
            title: 'Fecha venta',
            prop: 'fecha_venta',
            dineroFormat: false,
        },
        {
            title: 'Fecha de viaje',
            prop: 'fecha_viaje',
            dineroFormat: false,
        },
        {
            title: 'Origen',
            prop: 'terminal_origen',
            dineroFormat: false,
        },
        {
            title: 'Destino',
            prop: 'terminal_destino',
            dineroFormat: false,
        },
        {
            title: 'Marca',
            prop: 'marca',
            dineroFormat: false,
        },
        {
            title: 'Región',
            prop: 'region',
            dineroFormat: false,
        },
        {
            title: 'Situación',
            prop: 'situacion',
            dineroFormat: false,
        },
        {
            title: 'Precio',
            prop: 'precio',
            dineroFormat: true,
        },
        {
            title: 'Boletos',
            prop: 'boletos',
            dineroFormat: false,
        },
        {
            title: 'Subtotal',
            prop: 'subtotal',
            dineroFormat: true,
        },
        {
            title: 'IVA',
            prop: 'iva',
            dineroFormat: true,
        },
        {
            title: 'Total',
            prop: 'total',
            dineroFormat: true,
        },
        {
            title: 'Dependencia',
            prop: 'dependencia',
            dineroFormat: false,
        },
    ]

    const pdfDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficioregional/pdf/';
    const excelDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficioregional/excel/';
    const zipDownloadUrl = process.env.REACT_APP_BACKEND_URL + '/api/oficioregional/zip/';

    const [errorBody, setErrorBody] = useState('');
    const [fecha, setFecha] = useState(() => {
        const _fecha = new Date();
        return _fecha.toDateInputValue();
    });
    const [ordenes, setOrdenes] = useState(new Map());
    const [ordenesSelected, setOrdenesSelected] = useState(new Set());
    const [buscando, setBuscando] = useState(false);
    const [totales, setTotales] = useState(totalesInicial);
    const [generarFolioDisabled, setGenerarFolioDisalbled] = useState(true);
    const [generandoFolio, setGenerandoFolio] = useState(false);
    const [numeroOficio, setNumeroOficio] = useState('');

    // useEffect(() => {
    //     situacion_get({
    //         all: true,
    //         page_size: 0,
    //     }).then(result => {
    //         if (result.ok){
    //             setSituaciones(result.data);
    //         }
    //         else{
    //             setErrorBody(result.data);
    //             showModal(errorModalId);
    //         }
    //     })
    // }, []);

    useEffect(() => {
        // Actualizar totales
        let nOrdenes= ordenesSelected.size;
        let importe = 0;
        let subtotal = 0;
        let iva = 0;
        let total = 0;
        ordenesSelected.forEach(numorden => {
            const orden = ordenes.get(numorden);
            importe += orden.precio;
            subtotal += orden.subtotal;
            iva += orden.iva;
            total += orden.total;
        });
        setTotales({
            nOrdenes: nOrdenes,
            importe: importe,
            subtotal: subtotal,
            iva: iva,
            total: total,
        })

        // Actualizar botón generarFolio
        if (ordenesSelected.size <= 0){
            setGenerarFolioDisalbled(true);
        }else{
            let puedeGenerar = true;
            for (const numorden of ordenesSelected){
                const orden = ordenes.get(numorden);
                if (orden.oficio_regional_id !== null
                    || orden.situacion_id !== Situacion.Enum.RECIBIDA.id
                ){
                    puedeGenerar = false;
                    break;
                }
            }
            setGenerarFolioDisalbled(!puedeGenerar);
        }
    }, [ordenes, ordenesSelected]);


    const setAllOrdenesSelected = () => {
        const newSelected = new Set();
        ordenes.forEach(orden => {
            newSelected.add(orden.numorden);
        });
        setOrdenesSelected(newSelected);
    }

    const setNoneOrdenesSelected = () => {
        setOrdenesSelected(new Set());
    }

    const toggleOrdenSelected = (numorden) => {
        setOrdenesSelected(selected => {
            if (selected.has(numorden))
                selected.delete(numorden);
            else
                selected.add(numorden);

            const newSelected = new Set(selected);
            return newSelected;
        })
    }

    const buscar = (numeroOficio, regiones, dependenciaId, transportistaId, situacionId) => {
        setBuscando(true);
        setNoneOrdenesSelected();

        let region_ids = '';

        regiones.forEach(region_id => {
            region_ids += region_id.toString() + ',';
        });

        let busqueda = {}
        if (numeroOficio !== ''){
            if (/^(\d{5})-(\d{2})-(\d{4})/.test(numeroOficio)){
                busqueda = {
                    oficio_regional_id: numeroOficio
                }
            }else{
                busqueda = {
                    numorden: numeroOficio
                }

            }

        }else{
            setNumeroOficio('');
            busqueda = {
                region_ids: region_ids,
                dependencia_id: dependenciaId,
                situacion_id: situacionId,
                page_size: 0,
            }
        }

        ordenServicio_get(busqueda).then(result => {
            if(result.ok){
                const ordenes = new Map();
                result.data.forEach(orden => {
                    ordenes.set(orden.numorden, orden);
                });

                setOrdenes(ordenes);
            }
            else{
                // TODO: handle error
                setErrorBody(result.data);
                showModal(errorModalId);
            }
            setBuscando(false);
        });
    }

    const generarFolio = (dependenciaId, transportistaId, regionId) => {
        setGenerandoFolio(true);
        oficioRegionalFolio_post(fecha, Array.from(ordenesSelected), dependenciaId, transportistaId).then(
            result => {
                if (result.ok){
                    setNumeroOficio(result.oficio.id);

                    // Actualizar órdenes de servicio
                    const newOrdenes = new Map(ordenes);
                    ordenesSelected.forEach(numorden => {
                        const orden = ordenes.get(numorden);
                        orden.oficio_regional_id = result.oficio.id;
                        orden.situacion_id = result.oficio.situacion_id;
                        newOrdenes.set(numorden, orden);
                    });
                    setOrdenes(newOrdenes);

                    // Descargar archivos
                    window.open(zipDownloadUrl + result.oficio.id, '_blank');
                }else{
                    setErrorBody(result.data);
                    showModal(errorModalId);
                }
                setGenerandoFolio(false);
            }
        )
    }

    const limpiar = () => {
        setTotales(totalesInicial);
        setOrdenesSelected(new Set());
        setOrdenes(new Map());
        setNumeroOficio('');
    }

    return (
        <GestionBase
            title= {title}
            subtitle= {subtitle}
            image= {image}
            filtro = {<Filtro
                fecha = {fecha}
                setFecha = {setFecha}
                numeroOficio={numeroOficio}
                setNumeroOficio={setNumeroOficio}
                buscar = {buscar}
                generarFolio = {generarFolio}
                generarFolioDisabled = {generarFolioDisabled || generandoFolio}
                generandoFolio = {generandoFolio}
                limpiar = {limpiar}
                pdfDownloadUrl = {pdfDownloadUrl}
                excelDownloadUrl = {excelDownloadUrl}
            />}
            table = {<Tabla
                heads={tableHeads}
                entities= {ordenes}
                entityName="orden"
                entityIdName="numorden"
                selected= {ordenesSelected}
                setAll= {setAllOrdenesSelected}
                setNone= {setNoneOrdenesSelected}
                toggleSelected= {toggleOrdenSelected}
                buscando= {buscando}
            />}
            totales = {<Total totales={totales}/>}
        >
            <ModalElement modalId= {errorModalId} body={errorBody} showCancelar={false}/>
        </GestionBase>
    );
}